<template>
<div>
  <template v-if="articles.length">
    <div class="d-flex justify-content-left flex-wrap px-15">
      <div class="article-wrapper p-10" v-for="(article, index) in articles" :key="index">
        <ItemArticle class="large" :article="article"/>
      </div>
    </div>
    <AppPagination :meta="meta" @page="jump"/>
  </template>
  <template>
    <div class="not-found">
      <h4 class="text-center">我們搜尋不到任何相關文章</h4>
      <h5 class="text-center">請試試其他關鍵字</h5>
    </div>
  </template>
</div>

</template>

<script>
import { PostService, ProductService } from "@/apis/service";
export default {
  props: {
    query: Object,
  },
  data() {
    return {
      articles: [],
      meta: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchData(to))
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to)
    next()
  },
  computed: {

  },
  methods: {
    async fetchData (to) {
      const articleQuery = {
        orderBy: 'id',
        sortedBy: 'desc',
        limit: 12,
        page: to.query.page,
      }
      if (to.query.tag) {
        articleQuery['tag'] = to.query.tag
      }
      if (to.query.keyword) {
        const keyword = to.query.keyword.split(' ').join('|');
        articleQuery['search'] = `title:${to.query.keyword};content:${to.query.keyword}`
      }
      const articleResp = await PostService.query(articleQuery)
      this.articles = articleResp.data.data
      this.meta = articleResp.data.meta
      this.$store.commit('setLoading', false)
    },
    jump(page) {
      const query = Object.assign({...this.query}, {page: page})
      this.$router.push({ path: '/search/article', query})
    },
  }
}
</script>

<script setup>
import ItemArticle from '@/components/item/Article.vue'
import AppPagination from '@/components/app/Pagination.vue'
</script>

<style lang="scss" scoped>
.not-found{
  padding-top:20px;
  padding-bottom: 300px;
}
.article-wrapper {
  width: 25%;
  @include media-breakpoint-down(sm){
    width: 50%;
  }
  @include media-breakpoint-down(xs){
    width: 100%;
  }
}
</style>
